<template>
  <div style="padding: 24px;display: flex;justify-content: center;height: 80%">
    <div style="width: 30%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;height: 20%">

      <div style="margin-bottom: 16px;width: 100%">
        <a-input addon-before="旧密码" v-model="oldPwd"/>
      </div>

      <div style="margin-bottom: 16px;width: 100%">
        <a-input addon-before="新密码" v-model="newPwd"/>
      </div>
      <div style="width: 100%;margin-top: 10px;">
        <a-button type="primary" @click="submit">确认修改</a-button>
      </div>
    </div>
  </div>
</template>

<script>
const key = 123
export default {
  name: "ChangePwd",
  data() {
    return {
      oldPwd: '',
      newPwd: ''
    }
  },
  methods: {
    submit() {
      if (this.newPwd.trim().length < 1) {
        this.$Message.error({content: '密码不可为空', key, duration: 1})
        return
      }

      this.$axios.post('/user/api/UpdateUserProfile', {
        password: this.oldPwd.trim(),
        newpassword: this.newPwd.trim()
      }).then(e => {
        this.$Message.success({content: e.data.msg, key, duration: 1})
        setTimeout(() => {
          this.$store.commit("setF5Token", "");
          this.$store.commit("setUsrRole", "");
          this.$store.commit("setAcToken", "");
          this.$store.commit("setIsLogin", "0");
          this.$store.commit("setMail", "");
          this.$router.replace('/');
        }, 1000)
      }).catch(e => {
        this.$Message.error({content: e.msg, key, duration: 1})
      })
    }
  }
}
</script>

<style scoped>

</style>